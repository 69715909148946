import { Type } from 'class-transformer'
import { IsNumber, IsString, ValidateNested } from 'class-validator'
import { asDTO } from '@decet/core/shared/common'
import { EcommerceCarritoProducto } from './carrito-producto.dto'

export class Carrito {
  @ValidateNested({ each: true })
  @Type(() => EcommerceCarritoProducto)
  navegador!: EcommerceCarritoProducto[]

  @ValidateNested({ each: true })
  @Type(() => EcommerceCarritoProducto)
  usuario!: EcommerceCarritoProducto[]
}

export const CarritoDto = asDTO(Carrito)

export class CarritoPost {
  @IsString()
  productoCodigoEcommerce!: string

  @IsNumber()
  cantidad!: number
}

export const CarritoPostDto = asDTO(CarritoPost)
