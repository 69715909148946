import { IsNumber, IsNumberString, IsOptional, IsString, ValidateNested } from 'class-validator'
import { asDTO } from '@decet/core/shared/common'

export class EcommerceAgrupacion {
  @IsNumber()
  id!: number

  @IsString()
  codigo!: string

  @IsString()
  descripcion!: string

  @IsString()
  img!: string

  @ValidateNested()
  // @Type(() => any)
  productos!: any[]
}

export const EcommerceAgrupacionDto = asDTO(EcommerceAgrupacion)

export class EcommerceCatalogoAgrupacion {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  codigo!: string

  @IsString()
  descripcion!: string
}

export const EcommerceCatalogoAgrupacionDto = asDTO(EcommerceCatalogoAgrupacion)

export class EcommerceAgrupacionParams {
  @IsString()
  agrupacion!: string

  @IsOptional()
  @IsString()
  nivel1?: string

  @IsOptional()
  @IsString()
  nivel2?: string

  @IsOptional()
  @IsNumberString()
  nivel1Cod?: string | number
}

export const EcommerceAgrupacionParamsDto = asDTO(EcommerceAgrupacionParams)
