import { IsOptional, IsString } from 'class-validator'
import { CatalogoCiudadDto, CatalogoMunicipioDto, ZonaDto, CatalogoEstadoDto } from '@decet/catalogo-shared'

export class CatalogoDto {
  @IsOptional()
  id?: number
  descripcion!: string
}

export class DireccionDto {
  @IsOptional()
  id?: number
  @IsOptional()
  calle!: string
  @IsString()
  numeroInterior?: string
  @IsString()
  numeroExterior?: string
  zona!: ZonaDto | null
  municipio!: CatalogoMunicipioDto | null
  pais?: CatalogoDto | null
  estado!: CatalogoEstadoDto | null
  ciudad!: CatalogoCiudadDto | null
  delegacio!: CatalogoDto | null

  @IsString()
  codigoPostal!: string
  latitud?: number
  longitud?: number

  @IsString()
  entreCalles!: string

  @IsOptional()
  @IsString()
  referencia?: string

  @IsString()
  etiqueta!: string
}
